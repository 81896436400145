<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input v-model="searchForm.name" placeholder="公司名" clearable />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:companylevel:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:companylevel:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="补助级别名称"
      >
      </el-table-column>
      <el-table-column
        prop="subsidy"
        header-align="center"
        align="center"
        label="补助形式"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.subsidyType === 1" type="info" size="mini"
            >按天补助</el-tag
          >
          <el-tag v-else-if="scope.row.subsidyType === 2" size="mini"
            >按月补助</el-tag
          >
          <el-tag v-else-if="scope.row.subsidyType === 3" size="mini"
            >按次补助</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="amount"
        header-align="center"
        align="center"
        label="补助金额"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:companylevel:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:companylevel:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:companylevel:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './companylevel-add-or-update';
import { listMixin, normal } from '@/mixins';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      searchForm: {
        name: '',
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$getDataList({
        url: '/tc/companylevel/list',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.totalPage = data.page.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对选中的数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/companylevel/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
